import React, {useEffect} from "react"
import {Box} from "@mui/material";
import Map from "./map"
import DatePickerWidget from "./widget/date_picker";
import TimePickerWidget from "./widget/time_picker";
import DateLabelWidget from "./widget/datelabel";
import StyleSwitchWidget from "./widget/style_switch";
import {makeStyles} from "@mui/styles";
import UseRootData from "../data";
import DateAdapter from "@mui/lab/AdapterDayjs"
import {LocalizationProvider} from "@mui/lab";
import jaLocale from "dayjs/locale/ja"
import {Rows} from "../manager/carto";
import dayjs from "dayjs";
import KpostSwitchWidget from "./widget/kpost_switch";
import LegendsWidget from "./widget/legends";

const useStyles = makeStyles({
    root: {
        backgroundColor: "inherit",
    },
    map: {
        width: "100vw",
        height: "100vh",
    },
    datePicker: {
        position: "absolute",
        left: 0,
        zIndex: 2000,
        bottom: "100px",
        margin: "0 16px",
    },
    timePicker: {
        position: "absolute",
        left: 0,
        bottom: "20px",
        width: "calc(100% - 32px)",
        margin: "0 16px",
        zIndex: 2000,
    },
    dateLabel: {
        position: "absolute",
        bottom: "80px",
        right: "24px",
        zIndex: "800",
    },
    styleSwitch: {
        backgroundColor: "white",
        position: "absolute",
        bottom: "180px",
        left: "20px",
        zIndex: 400,
    },
    kpostSwitch: {
        backgroundColor: "white",
        position: "absolute",
        bottom: "240px",
        left: "20px",
        zIndex: 400,
    },
    legends: {
        position: "absolute",
        top: "18px",
        right: "18px",
        zIndex: 400,
    }
})

export const DataContext = React.createContext()

const RootView = (props) => {

    const classes = useStyles()
    const useRootData = UseRootData()
    const { setDate, setActiveDates } = useRootData

    useEffect(() => {
        Rows("SELECT DISTINCT date, _default FROM hw_calendar ORDER BY date")
            .then(res => {
                let defDate = null
                setActiveDates(res.map(v => {
                    if (v._default) {
                        defDate = v.date
                    }
                    return v.date
                }), () => {
                    setDate(dayjs(defDate))
                })

            })
    }, [])


    return (
        <DataContext.Provider value={useRootData}>
            <LocalizationProvider dateAdapter={DateAdapter} locale={jaLocale}>
                <Box className={classes.root}>
                    <Map className={classes.map} />
                    <DatePickerWidget className={classes.datePicker} />
                    <TimePickerWidget className={classes.timePicker} />
                    <DateLabelWidget className={classes.dateLabel} />
                    <StyleSwitchWidget className={classes.styleSwitch} />
                    <KpostSwitchWidget className={classes.kpostSwitch} />
                    <LegendsWidget className={classes.legends} />
                </Box>
            </LocalizationProvider>
        </DataContext.Provider>
    )
}

export default RootView
