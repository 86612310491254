
export default  `
@WHITE:#FFFFFF;
@spd0:#870000;
@spd10:#bf360c;
@spd20:#e64a19;
@spd30:#ff6f00;
@spd40:#f57f17;
@spd50:#fbc02d;
@spd60:#afb42b;
@spd70:#8bc34a;
@spd80:#9ccc65;

#main_basee{
  line-join:round;
  line-color:@WHITE;  
  
  [zoom<14]{line-width:5;line-offset:-2;}
  [zoom=14]{line-width:6;line-offset:-2;}
  [zoom=15]{line-width:7;line-offset:-1;}
  [zoom=16]{line-width:11;line-offset:-2;}
  [zoom=17]{line-width:13;}
  [zoom=18]{line-width:25;}
  [zoom=19]{line-width:45;}
  [zoom>=20]{line-width:85;}
}

#main_line{
  line-join:round;
  [zoom<14]{line-width:3;line-offset:-2;}
  [zoom=14]{line-width:4;line-offset:-2;}
  [zoom=15]{line-width:5;line-offset:-1;}
  [zoom=16]{line-width:9;line-offset:-2;}
  [zoom=17]{line-width:10;}
  [zoom=18]{line-width:20;}
  [zoom=19]{line-width:35;}
  [zoom>=20]{line-width:70;}
  line-color:@spd20;
}
`
export const Legends = [
    {color: "#870000", label: "渋滞 40km/h以下"},
]
