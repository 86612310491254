import React, {useContext} from "react"
import {DataContext} from "../../index";
import {Box, Button} from "@mui/material";
import {CheckBox as CheckBoxIcon, CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon } from "@mui/icons-material";
import {makeStyles} from "@mui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles({
    root: {
        backgroundColor: "inherit",
    }
})

const KpostSwitchWidget = (props) => {

    const { state, setShowKpost } = useContext(DataContext)
    const classes = useStyles()

    const onToggleShowKPost = () => {
        setShowKpost(!state.showKpost)
    }

    return (
        <Box className={props.className}>
            <Button
                onClick={onToggleShowKPost}
                className={classes.root}
                variant={state.showKpost ? "contained" : "outlined"}
                startIcon={state.showKpost ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon
                />}>
                キロポストを表示
            </Button>
        </Box>
    )
}

KpostSwitchWidget.propTypes = {
    className: PropTypes.string,
}

export default KpostSwitchWidget
