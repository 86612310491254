import React, {useContext} from "react"
import {Box, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {DataContext} from "../../index";
import PropTypes from "prop-types";


const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "row",
        height: "40px",
        borderRadius: "8px",
        border: "1px solid #000",
        width: "280px",
        boxShadow: "1px 1px 5px #9f9f9f91",
        overflow: "hidden",
    },
    switchButton: {
        width: "200px",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    selected: {
        backgroundColor: "#ffeca2",
        cursor: "default",
    }
})

const StyleSwitchWidget = (props) => {

    const classes = useStyles()
    const { state, setStyle } = useContext(DataContext)

    const onSwitch = (style) => {
        setStyle(style)
    }

    return (
        <Box className={classes.root + " " + props.className}>
            <Box
                onClick={() => { onSwitch("style1")}}
                className={classes.switchButton + " " + (state.style === "style1" && classes.selected)}>
                <Typography fontSize="small">スタイル１</Typography>
            </Box>
            <Box
                onClick={() => { onSwitch("style2")}}
                className={classes.switchButton + " " + (state.style === "style2" && classes.selected)}>
                <Typography fontSize="small">スタイル２</Typography>
            </Box>
        </Box>
    )
}

StyleSwitchWidget.propTypes = {
    className: PropTypes.string,
}

export default StyleSwitchWidget
