import {BrowserRouter, Routes, Route, Switch} from "react-router-dom";
import Root from "./views"

const App = () => {

  return (
      <BrowserRouter>
          <Routes>
              <Route exact path="/" element={<Root />} />
          </Routes>
      </BrowserRouter>
  )

}

export default App
