import {useContext, useEffect, useMemo, useState} from "react";
import {DataContext} from "../../index";
import PropTypes from "prop-types";
import {Rows} from "../../../manager/carto";
import * as L from "leaflet"


delete L.Icon.Default.prototype._getIconUrl
const infoIcon = L.icon({
    iconUrl: require("./../../../resources/info.png"),
    iconSize: [11,11],
    iconAnchor: [7,7],
    tooltipAnchor: [0,-14],
})
L.Marker.prototype.options.icon = infoIcon
console.log(infoIcon)



const MapPopupSummaryLayer = (props) => {

    const { state } = useContext(DataContext)
    let layerGroup = useMemo(() => {
        if (!props.map) { return null}
        return L.layerGroup().addTo(props.map)
    }, [props.map])

    const getTimeString = (sec) => {
        if (!sec) {
            return " -- "
        }
        if (sec > 86400) {
            return "1日以上"
        } else if (sec > 3600) {
            let h = parseInt(sec / 3600)
            sec -= h * 3600
            let m = parseInt(sec / 60)
            return `${h}時間${m}分`
        } else if(sec > 60) {
            return `${Math.ceil(sec / 60)}分`
        } else {
            return '<1分'
        }
    }

    useEffect(() => {
        if (!layerGroup || !state.date) { return }
        layerGroup.clearLayers()

        let ymd = state.date.format("YYYYMMDD")

        let sql = `
        WITH cte AS (
          SELECT
            cartodb_id,
            slot,
            start_kp,
            end_kp,
            jam_length,
            passage_time,
            CASE WHEN ST_GeometryType(ST_LineMerge(the_geom)) = 'ST_MultiLineString'
              THEN ST_LineInterpolatePoint(ST_GeometryN(the_geom,1),0.5)
              ELSE ST_LineInterpolatePoint(ST_LineMerge(the_geom),0.5)
            END AS the_geom
          FROM takuman.summary_${ymd}
          WHERE slot = ${state.timeSlot + 1}
        )
        SELECT *,
         ST_Y(the_geom) AS latitude,
         ST_X(the_geom) AS longitude
       FROM cte
        `

        Rows(sql)
            .then(ret => {
                console.log(sql, ret)
                ret.flatMap((e) => {
                    if (!e.latitude || !e.longitude) { return null }
                    let values = []
                    values.push(`渋滞先頭KP: ${e.end_kp ?? " -- "}`)
                    values.push(`渋滞末尾KP: ${e.start_kp ?? " -- "}`)
                    values.push(`渋滞距離: ${e.jam_length?.toLocaleString() ?? " -- "}m`)
                    values.push(`通過時間: ${getTimeString(parseInt(e.passage_time))}`)

                    return L.marker(L.latLng(e.latitude, e.longitude, {
                        icon: infoIcon,
                    })).bindTooltip(values.join("<br>"), { direction: "top"}).addTo(layerGroup)
                })
            })

        return () => {
            layerGroup?.clearLayers()
        }
    }, [layerGroup, state.date, state.timeSlot])


    return null
}

MapPopupSummaryLayer.propTypes = {
    map: PropTypes.any
}
export default MapPopupSummaryLayer
