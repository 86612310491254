
const RootDataState = {
    playing: false,
    activeDates: [],
    date: null,
    timeSlot: 0,
    slotSpeed: 1000,
    style: "style1",
    showKpost: false,
}

export default RootDataState
