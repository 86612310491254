import React, {useContext, useEffect, useState} from "react"
import {Box, Button, TextField, Typography} from "@mui/material";
import PropTypes from "prop-types";
import Calendar from "react-calendar"
import {makeStyles} from "@mui/styles";
import dayjs from "dayjs";
import {DataContext} from "../../index";
import 'react-calendar/dist/Calendar.css'
import { Event as CalendarIcon } from "@mui/icons-material"
import "dayjs/locale/ja"

dayjs.locale('ja')

const useStyles = makeStyles({
    root: {
        backgroundColor: "#ffffffd9",
        borderRadius: "8px",
        border: "1px solid #999",
        boxShadow: "1px 1px 5px #9f9f9f91",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
    },
    date: {
        backgroundColor: "white",
        borderRadius: "8px",
    },
    calendar: {
        backgroundColor: "transparent !important",
        border: "none !important"
    },
})

const DatePickerWidget = (props) => {
    const classes = useStyles()
    const [openCalendar, setOpenCalendar] = useState(false)
    const { state, setDate } = useContext(DataContext)
    const [selectedValue, setSelectedValue] = useState(dayjs().format("YYYY-MM-DD"))

    useEffect(() => {
        if (!state.date) { return }
        setSelectedValue(state.date?.toDate())
    }, [state.date])

    useEffect(() => {
        setSelectedValue(state.date?.toDate())
    }, [])

    const onCalendarCancel = () => {
        setOpenCalendar(false)
    }

    const onCalendarSubmit = () => {
        let d = dayjs(selectedValue)
        setDate(d)
        setOpenCalendar(false)
    }

    const onCalendarChanged = (value) => {
        setSelectedValue(value)
    }

    const onCalendarNewest = () => {
        if (!state.activeDates) { return }
        setDate(dayjs(state.activeDates[state.activeDates.length - 1]))
        setOpenCalendar(false)
    }

    const tileDisabled = (e) => {
        switch(e.view) {
            case "month":
                return !(state.activeDates ?? []).includes(dayjs(e.date).format("YYYY-MM-DD"))
            default:
                break
        }
        return true
    }

    return (
        <Box className={props.className}>
            {!openCalendar && (
                <Button onClick={() => setOpenCalendar(true)}>
                    <TextField
                        disabled={true}
                        className={classes.date}
                        value={state.date?.format("YYYY年MM月DD日 (ddd)") ?? "未設定"}
                        InputProps={{ endAdornment: <CalendarIcon /> }}
                    />
                </Button>
            )}
            {openCalendar && (
                <Box className={classes.root}>
                    <Calendar className={classes.calendar} value={selectedValue} onChange={onCalendarChanged} tileDisabled={tileDisabled} />
                    <Box style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <Button onClick={onCalendarNewest}>
                            <Typography variant="small">最新日</Typography>
                        </Button>
                        <Box flexGrow={1} />
                        <Button onClick={onCalendarSubmit}>
                            <Typography variant="small">確定</Typography>
                        </Button>
                        <Button onClick={onCalendarCancel}>
                            <Typography variant="small">キャンセル</Typography>
                        </Button>
                    </Box>
                </Box>
            )}
        </Box>
    )
}

DatePickerWidget.propTypes = {
    className: PropTypes.string,
    styles: PropTypes.any,
}

export default DatePickerWidget
