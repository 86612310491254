
export default `
@WHITE:#FFFFFF;
@spd0:#870000;
@spd10:#bf360c;
@spd20:#e64a19;
@spd30:#ff6f00;
@spd40:#f57f17;
@spd50:#fbc02d;
@spd60:#afb42b;
@spd70:#8bc34a;
@spd80:#9ccc65;

#main_basee[linkclass_c<3][spd>0][roadclass_c>=101]::main_line1{
  line-join:round;
  line-color:@WHITE;  
  [zoom<14]{line-width:5;line-offset:-2;}
  [zoom=14]{line-width:6;line-offset:-2;}
  [zoom=15]{line-width:7;line-offset:-1;}
  [zoom=16]{line-width:11;line-offset:-2;}
  [zoom=17]{line-width:13;}
  [zoom=18]{line-width:25;}
  [zoom=19]{line-width:45;}
  [zoom>=20]{line-width:85;}
}


#other_line[zoom>=14][linkclass_c>=3][spd>0][roadclass_c>=101]::etc_line1{
  line-width:0;
  line-join:round;
  [spd>=40]{line-color:@spd70;}
  [spd>=20][spd<40]{line-color:@spd50;}
  [spd>=10][spd<20]{line-color:@spd30;}
  [spd<10]{line-color:@spd20;}
  line-offset:1;
  [zoom=14]{line-width:3;line-offset:-2;}
  [zoom=15]{line-width:3;line-offset:-1;}
  [zoom=16]{line-width:4;line-offset:-2;}
  [zoom=17]{line-width:5;}
  [zoom=18]{line-width:7.5;}
  [zoom=19]{line-width:10;}
  [zoom>=20]{line-width:15;}
}

#main_line[linkclass_c<3][spd>0][roadclass_c>=101]{
  line-join:round;
  [zoom<14]{line-width:3;line-offset:-2;}
  [zoom=14]{line-width:4;line-offset:-2;}
  [zoom=15]{line-width:5;line-offset:-1;}
  [zoom=16]{line-width:9;line-offset:-2;}
  [zoom=17]{line-width:10;}
  [zoom=18]{line-width:20;}
  [zoom=19]{line-width:35;}
  [zoom>=20]{line-width:70;}
  
  
  [roadclass_c=101]{
    [spd>=80]{line-color:@spd80;}
    [spd>=70][spd<80]{line-color:@spd70;}
    [spd>=60][spd<70]{line-color:@spd60;}
    [spd>=50][spd<60]{line-color:@spd50;}
    [spd>=40][spd<50]{line-color:@spd40;}
    [spd>=30][spd<40]{line-color:@spd30;}
    [spd>=20][spd<30]{line-color:@spd20;}
    [spd>=10][spd<20]{line-color:@spd10;}
    [spd<10]{line-color:@spd0;}
  }
  [roadclass_c>=102]{
    [spd>=80]{line-color:@spd80;}
    [spd>=70][spd<80]{line-color:@spd70;}
    [spd>=60][spd<70]{line-color:@spd60;}
    [spd>=50][spd<60]{line-color:@spd50;}
    [spd>=40][spd<50]{line-color:@spd40;}
    [spd>=30][spd<40]{line-color:@spd30;}
    [spd>=20][spd<30]{line-color:@spd20;}
    [spd>=10][spd<20]{line-color:@spd10;}
    [spd<10]{line-color:@spd0;}
  }
}
`

export const Legends = [
    {color: "#9CCC65", label: "80km/h 〜"},
    {color: "#8BC34A", label: "70 〜 80km/h"},
    {color: "#AFB42B", label: "60 〜 70km/h"},
    {color: "#FBC02D", label: "50 〜 60km/h"},
    {color: "#F57F17", label: "40 〜 50km/h"},
    {color: "#FF6F00", label: "30 〜 40km/h"},
    {color: "#E64A19", label: "20 〜 30km/h"},
    {color: "#BF360C", label: "10 〜 20km/h"},
    {color: "#870000", label: "〜 10km/h"}
]
