import React, {useContext, useEffect, useState} from "react"
import {Box, IconButton, Slider, Typography} from "@mui/material";
import PropTypes from "prop-types";
import {DataContext} from "../../index";
import {PlayArrow as PlayArrowIcon, Pause as PauseIcon, } from "@mui/icons-material"
import { sprintf } from "sprintf-js"
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "row",
        gap: "8px",
        alignItems: "center",
        backgroundColor: "#f0f0f0c9",
        borderRadius: "8px",
        border: "1px solid #999",
        boxShadow: "1px 1px 5px #9f9f9f91",
    },
    slider: {
        margin: "16px",
    },
    timeLabel: {
        marginRight: "16px !important",
    },
})

const timeSliderMarks = [
    {
        value: 0,
        label: "00:00",
    },
    {
        value: 16,
        label: "04:00",
    },
    {
        value: 32,
        label: "08:00"
    },
    {
        value: 48,
        label: "12:00",
    },
    {
        value: 64,
        label: "16:00",
    },
    {
        value: 80,
        label: "20:00",
    }
]

const TimePickerWidget = (props) => {
    const classes = useStyles()
    const { state, togglePlaying, nextTimeSlot, setTimeSlot } = useContext(DataContext)
    const [ playTimer, setPlayTimer ] = useState(null)

    useEffect(() => {
        if (playTimer) {
            clearTimeout(playTimer)
            setPlayTimer(null)
            return
        }
        if (state.playing) {
            setPlayTimer(setInterval(() => {
                nextTimeSlot()
            }, 1000))
        }


    }, [state.playing])

    const sliderValueLabel = (value, index) => {
        let hour = Math.floor(value/ 4)
        let min = value % 4 * 15
        return sprintf("%02d", hour) + ":" + sprintf("%02d", min)
    }

    const onTogglePlaying = () => {
        togglePlaying()
    }

    const onSliderValueChanged = (event, value) => {
        setTimeSlot(value)
    }

    return (
        <Box className={classes.root + " " + props.className}>
            <IconButton onClick={onTogglePlaying}>
                {state.playing ? <PauseIcon fontSize="large" color="primary" /> : <PlayArrowIcon fontSize="large" color="primary" />}
            </IconButton>
            <Slider
                className={classes.slider}
                value={state.timeSlot}
                valueLabelFormat={sliderValueLabel}
                valueLabelDisplay="auto"
                step={1}
                marks={timeSliderMarks}
                min={0}
                max={95}
                onChange={onSliderValueChanged}
            />
            <Typography className={classes.timeLabel}>{sliderValueLabel(state.timeSlot)}</Typography>
        </Box>

    )

}

TimePickerWidget.propTypes = {
    className: PropTypes.string,
    style: PropTypes.any,
}

export default TimePickerWidget
