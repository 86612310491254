
export const ActionType = {
    SetDate: "SET_DATE",
    SetActiveDates: "SET_ACTIVE_DATES",
    SetTimeSlot: "SET_TIME_SLOT",
    NextTimeSlot: "NEXT_TIME_SLOT",
    TogglePlaying: "TOGGLE_PLAYING",
    SetSlotSpeed: "SET_SLOT_SPEED",
    SetStyle: "SET_STYLE",
    SetShowKpost: "SET_SHOW_KPOST",
}

const RootDataReducer = (state, action) => {

    switch(action.type) {
        case ActionType.SetDate:
            return {...state, date: action.value}
        case ActionType.SetActiveDates:
            return {...state, activeDates: action.value}
        case ActionType.SetTimeSlot:
            return {...state, timeSlot: action.value}
        case ActionType.NextTimeSlot:
            return {...state, timeSlot: state.timeSlot > 95 ? 0: state.timeSlot + 1}
        case ActionType.TogglePlaying:
            return {...state, playing: !state.playing}
        case ActionType.SetSlotSpeed:
            return {...state, slotSpeed: action.value}
        case ActionType.SetStyle:
            return {...state, style: action.value}
        case ActionType.SetShowKpost:
            return {...state, showKpost: action.value}
        default:
            break
    }
    return state
}

export default RootDataReducer
