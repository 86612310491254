import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react"
import * as L from "leaflet"
import 'leaflet/dist/leaflet.css'
import PropTypes from "prop-types";
import {makeStyles} from "@mui/styles";
import { MapContainer } from "react-leaflet";
import MapTrafficLayer from "./layer/traffic";
import MapKpostLayer from "./layer/kpost";
import {DataContext} from "../index";
import MapPopupSummaryLayer from "./layer/popupSummary";
import PopupSummary from "./layer/popupSummary";

const useStyles = makeStyles({
    root: {
        color: "inherit",
    }
})

const baseLayers = {
    "標準マップ": L.tileLayer(
        "https://d3a9yephh38bqr.cloudfront.net/EPSG:3857:{z}/{x}/{y}/",
        { tileSize: 256, maxNativeZoom: 18 }
    ),
    "白黒マップ": L.tileLayer(
        "https://d3a9yephh38bqr.cloudfront.net/EPSG:3857:{z}/{x}/{y}?mapstyle=gray_pc&logo=off&lang=ja&resolution=1&bldgname=off&landmarkicon=off",
        {tileSize: 256, }
    ),
    "地理院タイル(標準)": L.tileLayer(
        "https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png",
        { tileSize: 256, maxNativeZoom: 18 }
    ),
    "地理院タイル(単色)": L.tileLayer(
        "https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png",
        { tileSize: 256, maxNativeZoom: 18 }
    ),
    "地理院タイル(写真)": L.tileLayer(
        "https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg",
        { tileSize: 256, maxNativeZoom: 18 }
    ),
}

const MapView = (props) => {
    const classes = useStyles()
    const { state } = useContext(DataContext)
    const [map, setMap] = useState(null)

    useEffect(() => {
        if (!map) { return }
        L.control.layers(baseLayers, null, { position: "topleft"}).addTo(map)
        map.addLayer(baseLayers["標準マップ"])
    }, [map])

    return (
        <MapContainer
            center={[35.61916870724515, 139.18998474894073]}
            zoom={14}
            maxZoom={18}
            minZoom={9}
            className={props.className}
            whenCreated={(m) => setMap(m)} >
            <MapTrafficLayer map={map} />
            <MapPopupSummaryLayer map={map} />
            {state.showKpost && <MapKpostLayer map={map} />}
        </MapContainer>
    )
}

MapView.propTypes = {
    className: PropTypes.string,
    style: PropTypes.any,
}

export default MapView
