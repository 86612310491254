import {makeStyles} from "@mui/styles";
import {useContext, useEffect, useState} from "react";
import {DataContext} from "../../index";
import { Legends as style1Legends } from "../../map/layer/style1"
import { Legends as style2Legends } from "../../map/layer/style2"
import PropTypes from "prop-types";
import {Box, Divider, Typography} from "@mui/material";

const useStyles = makeStyles({
    root: {
        padding: "0 18px",
        border: "1px solid #999",
        boxShadow: "1px 1px 5px #9f9f9f91",
        borderRadius: "4px",
        minWidth: "160px",
        backgroundColor: "#f5f5f5cc",
    },
    title: {
        margin: "8px !important",
        textAlign: "center",
    },
    list: {
        listStyle: "none",
        paddingLeft: 0,
        marginTop: "4px",
    },
    listItem: {
        display: "flex",
        gap: "8px",
        alignItems: "center",
    },
    color: {
        width: "16px",
        height: "16px",
        borderRadius: "8px",
        margin: "4px 8px",
    },
    label: {
        fontSize: "12px",
    },
})

const LegendsWidget = (props) => {
    const classes = useStyles()
    const { state } = useContext(DataContext)
    const [legend, setLegend] = useState([])

    useEffect(() => {
        switch(state.style) {
            case "style1":
                setLegend(style1Legends)
                break
            case "style2":
                setLegend(style2Legends)
                break
            default:
                break
        }
    }, [state.style])

    return (
        <Box className={classes.root + " " + props.className}>
            <Typography variant="body2" className={classes.title}>凡例</Typography>
            <Divider style={{marginBottom: "12px"}} />
            <ul className={classes.list}>
                {legend.map((e) => {
                    return (
                        <li className={classes.listItem}>
                            <div className={classes.color} style={{backgroundColor: e.color}}> </div>
                            <div className={classes.label}>{e.label}</div>
                        </li>
                    )
                })}
            </ul>
        </Box>
    )
}

LegendsWidget.propTypes = {
    className: PropTypes.string,
}

export default LegendsWidget
