import {useContext, useEffect, useMemo} from "react";
import {DataContext} from "../../index";
import {
    CreateCartoCss,
    CreateCartoLayer,
    CreateCartoQuerySource,
    GetLeafletLayer,
    RemoveCartoLayer
} from "../../../manager/carto";
import PropTypes from "prop-types";


const MapKpostLayer = (props) => {

    const { state } = useContext(DataContext)


    useEffect(() => {
        if (!props.map) { return null }

        const cartoSource = CreateCartoQuerySource(`
        SELECT *,CASE WHEN Right(trunc(distance::numeric,1)::text,1) = '0' THEN 1::INTEGER ELSE 0::INTEGER END as flag FROM takuman.dp_201080
        `)
        const cartoCss = CreateCartoCss(`
            #layer::kp {
      [zoom<17][direction_c=2]{text-opacity:0;text-halo-radius:0;}
      text-name: [distance];
      text-face-name: 'DejaVu Sans Book';
      text-size: 9;
      [zoom>=17]{text-size: 11;}
      text-fill: #111;
      text-halo-radius: 2;
      text-halo-fill: #FFF;
      text-allow-overlap: true;
      text-placement: point;
      text-placement-type: dummy;
      [zoom=15]{text-dy:-10;}
      [zoom<=14]{
        [flag=0]{text-opacity:0;text-halo-radius:0;}
        [flag=1]{text-size:11;text-dy:-5;}
      }
      [zoom<=11]{text-opacity:0;text-halo-radius:0;}
      }`)

        const cartoLayer = CreateCartoLayer(cartoSource, cartoCss)

        let layer = GetLeafletLayer(cartoLayer)
        layer.setZIndex(3000)
        layer.addTo(props.map)

        return () => {
            RemoveCartoLayer(cartoLayer)
//            props.map.removeLayer(layer)
        }

    }, [props.map])

    return null
}

MapKpostLayer.propTypes = {
    map: PropTypes.any,
}

export default MapKpostLayer
