import {useReducer} from "react";
import initState from "./state"
import reducer, {ActionType} from "./reducer"

const UseRootData = () => {

    const [state, dispatch] = useReducer(reducer, {
        ...initState,
    })

    const setDate = (date, callback) => {
        dispatch({type: ActionType.SetDate, value: date})
        callback && callback()
    }

    const setActiveDates = (dates, callback) => {
        dispatch({type: ActionType.SetActiveDates, value: dates})
        callback && callback()
    }

    const setTimeSlot = (timeSlot, callback) => {
        dispatch({type: ActionType.SetTimeSlot, value: timeSlot})
        callback && callback()
    }

    const nextTimeSlot = (callback) => {
        dispatch({type: ActionType.NextTimeSlot})
        callback && callback()
    }

    const setSlotSpeed = (speed, callback) => {
        dispatch({type: ActionType.SetSlotSpeed, value: speed})
        callback && callback()
    }

    const togglePlaying = (callback) => {
        dispatch({type: ActionType.TogglePlaying})
        callback && callback()
    }

    const setStyle = (style, callback) => {
        dispatch({type: ActionType.SetStyle, value: style})
        callback && callback()
    }

    const setShowKpost = (visible, callback) => {
        dispatch({type: ActionType.SetShowKpost, value: visible})
        callback && callback()
    }


    return {
        state,
        setDate,
        setActiveDates,
        setTimeSlot,
        nextTimeSlot,
        togglePlaying,
        setSlotSpeed,
        setStyle,
        setShowKpost,
    }
}

export default UseRootData
